/** @jsx jsx */
import { jsx } from 'theme-ui'
import SectionTitle from './SectionTitle'

export default ({ className }) => (
  <div className={className}>
    <SectionTitle className='section-about'>About</SectionTitle>
    <p>Hi, I'm Eunjae Lee.</p>
    <p>
      I'm a software Engineer at Algolia, in Paris. I get excited when learning new things and love
      building tools to make life easier.
    </p>
    <p>
      I was born in South Korea. I moved to Singapore in 2017. Then, I've moved into Paris in 2019
      to join Algolia. I'm very happy with my decisions and open to answer your questions if you
      have any!
    </p>
  </div>
)
