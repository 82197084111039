/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'

export default ({ to, text = 'more' }) => (
  <div
    sx={{
      fontSize: 'sm',
      mt: 4,
      '& a': {
        display: 'inline-block',
        py: 2,
        px: 0,
        color: 'blue',
        opacity: 0.8,
        textDecoration: 'none',
        textTransform: 'uppercase',

        '&:hover': {
          textDecoration: 'underline',
          opacity: 1,
        },
      },
    }}
  >
    <Link to={to}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>{text}</span>
        <svg style={{ width: '16px', height: '16px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z'
          />
        </svg>
      </div>
    </Link>
  </div>
)
