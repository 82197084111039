/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'

const Header = ({ className }) => (
  <nav
    className={className}
    sx={{
      display: 'flex',
      justifyContent: ['center', 'flex-start'],
    }}
  >
    <ul
      sx={{
        m: 0,
        p: 0,
        listStyle: 'none',
        display: 'flex',
        flexDirection: ['row', 'column'],
        ml: [0, 2],
      }}
    >
      {[
        { selector: '.section-recent-posts', text: 'Posts', emoji: '✍️' },
        { selector: '.section-series', text: 'Series', emoji: '🍡' },
        { selector: '.section-projects', text: 'Projects', emoji: '🔥' },
        { selector: '.section-talks', text: 'Talks', emoji: '🎤' },
        { selector: '.section-about', text: 'About', emoji: '🧵' },
      ].map(({ selector, text, emoji }) => (
        <li
          key={text}
          sx={{
            mb: [0, 2],
            mr: 4,
          }}
        >
          <span
            sx={{
              display: ['none', 'inline'],
              ml: 1,
              mr: 2,
            }}
          >
            {emoji}
          </span>
          <button
            type="button"
            sx={{
              border: 0,
              m: 0,
              p: 0,
              backgroundColor: 'transparent',
              fontSize: 'sm',
              fontWeight: 'normal',
              color: 'blue',
              opacity: 0.8,
              textDecoration: 'none',
              textTransform: ['inherit', 'uppercase'],
              letterSpacing: ['inherit', '0.08rem'],
              cursor: 'pointer',
              '&:hover': {
                opacity: 1,
                textDecoration: 'underline',
              },
            }}
            onClick={() => {
              if (typeof document !== 'undefined') {
                document.querySelector(selector).scrollIntoView()
              }
            }}
          >
            {text}
          </button>
        </li>
      ))}
      <li>
        <Link
          to="/search"
          sx={{ lineHeight: 'none', display: 'flex', alignItems: 'center', textDecoration: 'none' }}
        >
          <svg
            sx={{
              width: 6,
              height: 6,
              color: 'black',
              opacity: 0.6,
              '&:hover': { opacity: 0.8 },
              ml: [0, 1],
            }}
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
            />
          </svg>
          <span
            sx={{
              display: ['none', 'inline'],
              ml: 1,
              fontSize: 'base',
              fontWeight: 'normal',
              color: 'blue',
              opacity: 0.8,
              textDecoration: 'none',
              textTransform: 'uppercase',
              letterSpacing: '0.08rem',
              '&:hover': {
                opacity: 1,
                textDecoration: 'underline',
              },
            }}
          >
            Search
          </span>
        </Link>
      </li>
    </ul>
  </nav>
)

export default Header
