/** @jsx jsx */
import { jsx } from 'theme-ui'
import SectionedList from './SectionedList'

export default ({ className }) => (
  <SectionedList
    className={className}
    sectionClassName='section-talks'
    title='Talks'
    list={[
      {
        title: 'How to Release Open Source Library in 2020',
        description: `In this talk, I will address the problems with the common release process and how to improve the process. Let's equip ourselves with the automated, asynchronous and collaborative release process.`,
        url: 'https://holyjs-piter.ru/en/2020/spb/talks/1uxdidm1aespm7dja3dl6y/',
        description2: 'HolyJS @ June 22-25, 2020',
      },
    ]}
  />
)
