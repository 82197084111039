/** @jsx jsx */
import { jsx } from 'theme-ui'
import GitHub from './icons/GitHub'
import Twitter from './icons/Twitter'
import Instagram from './icons/Instagram'

export default ({ className }) => (
  <div className={className}>
    <h1
      sx={{
        mt: 0,
        fontSize: '4xl',
        fontWeight: 'black',
        color: 'black',
        textAlign: 'center',
      }}
    >
      I'm Eunjae -
    </h1>
    <div sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <p sx={{ fontSize: 'base', color: 'black', m: 0 }}>
        A software engineer
        <br />
        focused on web development.
        <br />
        I'm working at Algolia, in Paris.
        <br />
        Feel free to connect!
      </p>
      <p>
        {[
          {
            href: 'https://github.com/eunjae-lee/',
            Icon: GitHub,
          },
          {
            href: 'https://twitter.com/eunjae_lee',
            Icon: Twitter,
          },
          {
            href: 'https://www.instagram.com/eunjae.dev/',
            Icon: Instagram,
          },
        ].map(({ href, Icon }, index) => (
          <a href={href} key={index}>
            <Icon
              sx={{
                mr: 2,
                color: 'blue',
                opacity: 0.7,
                '&:hover': {
                  opacity: 1,
                },
              }}
            />
          </a>
        ))}
      </p>
    </div>
  </div>
)
