/** @jsx jsx */
import { jsx } from 'theme-ui'

export default ({ className, children }) => (
  <div className={className}>
    <h2
      sx={{
        display: 'inline-block',
        fontSize: 'sm',
        fontWeight: 'normal',
        textTransform: 'uppercase',
        letterSpacing: '0.08rem',
        color: 'blue',
        borderBottom: 'lightBlue',
        m: 0,
      }}
    >
      {children}
    </h2>
  </div>
)
