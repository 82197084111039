/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import { getBlogUrl, toPlainText } from '../lib/helpers'
import PortableText from './PortableText'
import SectionTitle from './SectionTitle'
import BrowseMoreButton from './BrowseMoreButton'

function BlogPostPreviewList(props) {
  return (
    <div className={props.className}>
      {props.showRecentPostsTitle && (
        <SectionTitle className="section-recent-posts">Recent posts</SectionTitle>
      )}

      <ul
        sx={{
          m: 0,
          p: 0,
          listStyle: 'none',
        }}
      >
        {props.nodes &&
          props.nodes.map(node => (
            <li
              key={node.id}
              sx={{
                mt: 8,
              }}
            >
              <Link
                sx={{
                  color: 'inherit',
                  textDecoration: 'none',
                  '&:hover h3': {
                    textDecoration: 'underline',
                  },
                }}
                to={getBlogUrl(
                  node.slug.current,
                  node.series && node.series.series && node.series.series.slug
                )}
              >
                <div>
                  <h3
                    sx={{
                      fontWeight: 'semibold',
                      fontSize: 'xl',
                      m: 0,
                    }}
                  >
                    {node.title}
                  </h3>
                  {node._rawExcerpt && (
                    <div
                      sx={{
                        fontSize: 'base',
                        '& p': {
                          my: 1,
                          mx: 0,
                        },
                      }}
                    >
                      <PortableText blocks={node._rawExcerpt} />
                    </div>
                  )}
                  {!node._rawExcerpt && (
                    <p
                      sx={{
                        fontSize: 'base',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        my: 1,
                        mx: 0,
                      }}
                    >
                      {toPlainText(node._rawBody)}
                    </p>
                  )}
                  <div
                    sx={{
                      fontSize: 'xs',
                      opacity: 0.6,
                    }}
                  >
                    {format(node.publishedAt, 'MMMM Do, YYYY')}
                  </div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
      {props.browseMoreHref && <BrowseMoreButton to={props.browseMoreHref} />}
    </div>
  )
}

BlogPostPreviewList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
}

export default BlogPostPreviewList
