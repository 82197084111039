/** @jsx jsx */
import { jsx } from 'theme-ui'
import SectionedList from './SectionedList'

export default ({ className }) => (
  <SectionedList
    className={className}
    sectionClassName='section-projects'
    title='Open Source Projects'
    list={[
      {
        title: 'Ship.js',
        description: 'Take control of what is going to be your next release.',
        url: 'https://github.com/algolia/shipjs',
      },
      {
        title: 'tiny-fsm',
        description: 'A tiny library for finite state machine',
        url: 'https://github.com/eunjae-lee/tiny-fsm',
      },
      {
        title: 'create-gatsby-theme',
        description: 'Create a gatsby theme with pre-built template',
        url: 'https://github.com/eunjae-lee/create-gatsby-theme',
      },
    ]}
  />
)
