/** @jsx jsx */
import { jsx } from 'theme-ui'
import SectionedList from './SectionedList'

export default ({ className, nodes }) => (
  <SectionedList
    className={className}
    sectionClassName="section-series"
    title="Series"
    list={nodes.map(({ title, description, slug }) => ({
      title,
      description,
      url: `/${slug}`,
    }))}
  />
)
