/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { filterPostNodesFromEdges } from '../lib/helpers'
import BlogPostPreviewList from '../components/BlogPostPreviewList'
import Container from '../components/Container'
import GraphQLErrorList from '../components/GraphQLErrorList'
import SEO from '../components/SEO'
import Hello from '../components/Hello'
import Series from '../components/Series'
import ProjectList from '../components/ProjectList'
import TalkList from '../components/TalkList'
import About from '../components/About'
import Header from '../components/Header'
import LayoutContainer from '../containers/LayoutContainer'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        isKorean: { ne: true }
        hiddenFromList: { ne: true }
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        series: { _type: { eq: null } }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          _rawExcerpt
          slug {
            current
          }
          series {
            _key
            _type
            series {
              id
              title
              slug
            }
          }
        }
      }
    }
    series: allSanitySeries {
      edges {
        node {
          id
          title
          description
          slug
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    )
  }

  const site = (data || {}).site
  const postNodes = filterPostNodesFromEdges((data || {}).posts)
  const seriesNodes = (data || {}).series.edges.map(edge => edge.node)

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <LayoutContainer>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container
        sx={{
          mt: [0, 4],
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 3fr'],
          gap: [8, 16],
        }}
      >
        <div
          sx={{
            minWidth: '13.5rem',
            display: 'flex',
            flexDirection: ['column', 'column-reverse'],
            justifyContent: ['flex-start', 'flex-end'],
          }}
        >
          <Header sx={{ mb: 8 }} />
          <Hello sx={{ mb: 8 }} />
        </div>
        <div
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 20,
          }}
        >
          {postNodes && (
            <BlogPostPreviewList showRecentPostsTitle nodes={postNodes} browseMoreHref="/posts" />
          )}
          <Series nodes={seriesNodes} />
          <ProjectList />
          <TalkList />
          <About />
        </div>
      </Container>
    </LayoutContainer>
  )
}

export default IndexPage
