/** @jsx jsx */
import { jsx } from 'theme-ui'
import SectionTitle from './SectionTitle'
import { Link as GatsbyLink } from 'gatsby'

function Link({ className, url, children }) {
  if (url.startsWith('/')) {
    return (
      <GatsbyLink className={className} to={url}>
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <a className={className} href={url}>
        {children}
      </a>
    )
  }
}

export default ({ className, sectionClassName, title, list }) => (
  <div className={className}>
    <SectionTitle className={sectionClassName}>{title}</SectionTitle>

    {list.map(({ title, description, url, description2 }) => (
      <Link
        key={url}
        sx={{
          mt: 8,
          display: 'block',
          color: 'inherit',
          textDecoration: 'none',
          '&:hover h3': {
            textDecoration: 'underline',
          },
        }}
        url={url}
      >
        <div>
          <h3
            sx={{
              fontWeight: 'semibold',
              fontSize: 'xl',
              m: 0,
            }}
          >
            {typeof title === 'function' ? title() : title}
          </h3>
          <p sx={{ fontSize: 'base', my: 1, mx: 0 }}>{description}</p>
          {description2 && (
            <div
              sx={{
                fontSize: 'xs',
                opacity: 0.6,
              }}
            >
              {description2}
            </div>
          )}
        </div>
      </Link>
    ))}
  </div>
)
